import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize, firstValueFrom, Observable, Subject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SignedUser, TokensDto, WhoAmI } from '../types';
import { StorageService } from './storage.service';
import { User } from '../types/auth/user.class';

const base = environment.api + '/auth/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _whoAmI: WhoAmI | null = null;

  private _token: string | null = null;
  private _refreshToken: string | null = null;

  private _refreshing: boolean = false;
  private _refresh$ = new Subject<TokensDto>();

  constructor(
    private readonly http: HttpClient,
    private readonly storage: StorageService
  ) { }

  async init() {
    const token: string | null = await this.storage.get('token');
    const refreshToken: string | null = await this.storage.get('refreshToken');

    if (token) {
      this._token = token;

      if (refreshToken) {
        this._refreshToken = refreshToken;
      }

      await this.loadUserData();
    }
  }

  get isLogged(): boolean {
    return !!this._token;
  }

  get token(): string | null {
    return this._token;
  }

  set token(token: string | null) {
    if (token) {
      this.storage.set('token', token);
    } else {
      this.storage.remove('token');
    }

    this._token = token;
  }

  get refreshToken(): string | null {
    return this._refreshToken;
  }

  set refreshToken(refreshToken: string | null) {
    if (refreshToken) {
      this.storage.set('refreshToken', refreshToken);
    } else {
      this.storage.remove('refreshToken');
    }

    this._refreshToken = refreshToken;
  }

  get whoAmI(): WhoAmI | null {
    return this._whoAmI;
  }

  set whoAmI(whoAmI: WhoAmI | null) {
    this._whoAmI = whoAmI;
    User.instance.setWhoAmI(whoAmI);
  }

  setWhoAmI(signedUser: SignedUser) {
    const { token, refreshToken, ...whoAmI } = signedUser;

    this.token = token;
    this.refreshToken = refreshToken;

    this.whoAmI = whoAmI;
  }

  async loadUserData(): Promise<WhoAmI> {
    const observable$ = this.http.get<WhoAmI>(`${ base }/who-am-i`).pipe(
      tap((whoAmI: WhoAmI) => this.whoAmI = whoAmI),
      catchError(async (err) => {

        if (err.status === 401) {
          throw err;
        }

        if (err.status === 403) {
          this.logout();
          throw err;
        }

        // await 500 ms before retrying
        await new Promise((resolve) => setTimeout(resolve, 500));

        return this.loadUserData();
      })
    );

    return await firstValueFrom(observable$);
  }

  login(email: string, password: string): Observable<SignedUser> {
    return this.http.post<SignedUser>(`${ base }/sign-in`, { email, password }).pipe(
      tap((signedUser: SignedUser) => this.setWhoAmI(signedUser))
    );
  }

  async logout() {
    this.token = null;
    this.whoAmI = null;
    
    location.href = '/auth';
  }

  renewAccessToken(): Observable<TokensDto> {
    const pending = this._refreshing;

    this._refreshing = true;
    
    // si ya hay una petición en curso, devolvemos el observable del subject
    // de esta forma no se realizan peticiones duplicadas

    const url = [environment.api, 'auth', 'refresh-token'].join('/');

    return pending ? this._refresh$.asObservable() : this.http.post<TokensDto>(url, {
      refreshToken: this.refreshToken
    }).pipe(
      finalize(() => this._refreshing = false),
      tap(async (tokens: TokensDto) => {
        const { token, refreshToken } = tokens;

        this.token = token;
        this.refreshToken = refreshToken;

        this._refresh$.next(tokens);
      }),
    );
  }
}
