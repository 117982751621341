import { Component, OnInit } from '@angular/core';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { AuthService } from './global/services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  environment = environment.api;
  production = environment.production;

  constructor(public readonly auth: AuthService) {
    defineCustomElements(window);
  }
  
  ngOnInit() {
    if ( location.hostname === 'localhost' || location.hostname.startsWith('192.168') ) {
      document.title = document.title + ` (localhost)`;
    } else if ( !this.production ) {
      document.title = document.title + ` (development)`;
    }
  }

  logout() {
    Swal.fire({
      text: '¿Estás seguro que deseas cerrar sesión?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, cerrar sesión',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      toast: true,
      customClass: {
        confirmButton: 'btn-danger'
      },
    })
      .then(({ isConfirmed }) => {
        if (isConfirmed)
          this.auth.logout()
      });

  }
}
