import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './global/guards/auth.guard';
import { NoAuthorizedGuard } from './global/guards/no-authorized.guard';
import { PagesGuard } from './global/guards/pages.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then( m => m.AuthPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'partes',
    loadChildren: () => import('./pages/partes/partes.module').then( m => m.PartesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'parte/:id/:type',
    loadChildren: () => import('./pages/parte/parte.module').then( m => m.PartePageModule),
    canActivate: [AuthGuard],
    canLoad: [PagesGuard]
  },
  {
    path: 'parte/:id/:type/edition',
    loadChildren: () => import('./pages/parte-edition/parte-edition.module').then( m => m.ParteEditionPageModule),
    canActivate: [AuthGuard],
    canLoad: [PagesGuard]
  },
  {
    path: 'maps',
    loadChildren: () => import('./pages/maps/maps.module').then( m => m.MapsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'no-authorized',
    loadChildren: () => import('./pages/no-authorized/no-authorized.module').then( m => m.NoAuthorizedPageModule),
    canActivate: [NoAuthorizedGuard]
  },
  {
    path: '**',
    redirectTo: 'partes',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
