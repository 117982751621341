import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { WorkType } from '../models/parte.models';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PagesGuard implements CanLoad {

  constructor(
    private readonly auth: AuthService,
    private readonly router: Router
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const segment: UrlSegment = segments[segments.length - 1];
    const path: WorkType = segment.path as WorkType;

    let canLoad: boolean  = false;

    switch (path) {
      case WorkType.ASPHALTS:
        canLoad = !!this.auth.whoAmI?.asphalts;
        break;
      case WorkType.SACKS:
        canLoad = !!this.auth.whoAmI?.sacks;
        break;
      case WorkType.FENCES:
        canLoad = !!this.auth.whoAmI?.fences;
        break;
      default: 
        canLoad = !!this.auth.whoAmI?.works;
        break;
    }

    if (!this.auth.isLogged) {
      this.router.navigate(['/auth']);
    } else if (!canLoad) {
      const nextPath = this._getNextPath(path);
      this.router.navigate(['/partes', nextPath]);
    }

    return canLoad;
  }

  private _getNextPath(path: WorkType): string {
    // 1. Convert enum WorkType to array values
    // 2. Get the index of the current path
    // 3. Get the next index (if the current path is the last one, the next index will be 0)
    // 4. Return the next path

    const paths = Object.values(WorkType);
    const index = paths.indexOf(path);
    const nextIndex = (index + 1) % paths.length;

    return paths[nextIndex];
  }
}
