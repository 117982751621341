import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(
    private readonly auth: AuthService,
    private readonly router: Router
  ) {}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    { url }: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if ( url === '/auth' ) {
      if ( this.auth.isLogged ) {
        this.router.navigate(['/partes']);
        return false;
      }
      
      return true;
    } else if ( !this.auth.isLogged ) {
      this.auth.logout();
    }

    return this.auth.isLogged;
  }
  
}
