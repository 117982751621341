import { WhoAmI } from "./who-am-i.dto";

export class User {
  private static whoAmI: WhoAmI | null = null;

  // Singleton
  private static _instance: User;

  static get instance(): User {
    if (!User._instance) {
      User._instance = new User();
    }

    return User._instance;
  }

  static get officerId(): number {
    return User.whoAmI?.officerId || -1;
  }

  setWhoAmI(whoAmI: WhoAmI | null): void {
    User.whoAmI = whoAmI;
  }
}
